// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mazone-js": () => import("./../src/pages/mazone.js" /* webpackChunkName: "component---src-pages-mazone-js" */),
  "component---src-pages-pellegrino-js": () => import("./../src/pages/pellegrino.js" /* webpackChunkName: "component---src-pages-pellegrino-js" */),
  "component---src-pages-pfd-js": () => import("./../src/pages/pfd.js" /* webpackChunkName: "component---src-pages-pfd-js" */)
}

